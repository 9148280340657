import React, {useState, useEffect} from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import api from "../../actions/riveroApi";
import ModalPromociones from "../widgets/modalPromociones";
import GatsbyImage from "gatsby-image";

export default function CatalogoPromos({promoType, props}){

    const [ promos, setPromos ] = useState([]);
    const [ selectPromo, setSelectPromo ] = useState();
    const [ showModal, setShowModal ] = useState(false);

    useEffect(() => {
        api.getPromociones().then((resp) => {
           const filterPromos = resp.data.filter((d) => d.type.toLowerCase() === promoType);
           setPromos(filterPromos);
        }).catch((error) => console.log("Error: ", error));
    }, []);

    function onhandlePromo (obj){
        setShowModal(true)
        setSelectPromo(obj)
    }


    return(
        <Row className="mt-3">
            {promos.length > 0 ? (
                promos.map((obj, i) => (
                    <Col xs={12} md={6} xl={3} className="mb-1 px-1" key={i}>
                        <a href="javascrip:;" className="rounded shadow-sm" onClick={() => {onhandlePromo(obj)}}>
                        <GatsbyImage
                            fluid={{
                                aspectRatio: 1 / 1,
                                src: `${obj.image}`,
                                srcSet: `${obj.image}, ${obj.image}`,
                                sizes: "quality: 80",
                            }}
                            className="w-100 rounded"
                            imgStyle={{objectFit: 'unset'}}
                        />
                        <h3 style={{ overflow: "hidden", height: 25, fontSize: 14, color: "#a2a2a2"}}>
                            {/*{promo.description.substring(0, 29)}...*/}
                        </h3>
                        </a>
                    </Col>
                ))
            ): (
               
                [1, 2, 3, 4, 5, 6].map((i) => ( 
                    <Col xs={12} md={6} lg={4} xl={3} key={i}>
                        <div xs={12} md={6} xl={3} className="blink"
                            style={{
                                margin: 2,
                                width: '100%',
                                height: 350,
                                backgroundColor: `rgba(0, 0, 0, 0.15)`,
                                marginLeft:  0,
                                marginRight:  0,
                            }}
                            >
                            <h3 style={{ overflow: "hidden", height: 50, fontSize: 14, color: "#a2a2a2"}}>
                                {/*{promo.description.substring(0, 29)}...*/}
                            </h3>
                        </div>
                    </Col>
                ))
                    
            )}
            <ModalPromociones show={showModal} onHide={() => setShowModal(false)} selectPromo={selectPromo} promoType={promoType} props={props}/>
        </Row>
    );

}