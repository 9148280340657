import React from "react";
import { Container, Row } from "react-bootstrap";
import GatsbyImage from "gatsby-image";
import CatalogoPromos from "../../components/layouts/CatalogoPromos";

import Layout from "../../components/layouts/layout";
import SEO from "../../components/seo";

export default function Promociones(props){

    const parameters = props.location.search;

    return(
        <Layout selectedMenuItem={"promo-cars"} parameters={parameters}>
            <SEO
                title="Nissan Rivero - Agencia Nissan Monterrey - Promociones Nissan"
                description="Nissan Rivero - Agencia Nissan Monterrey - Promociones Nissan Autos Nuevos - Seminuevos - Autos Meses sin intereses - Seminuevos Monterrey - Seminuevos Agencia"
                keywords="Agencia Nissan"
            />
            <Container>

                <Row>
                    <h1 className="mt-3" style={{ fontSize: 18, color: "#a2a2a2" }}>
                        Promociones agencia Nissan
                    </h1>
                </Row>

                <CatalogoPromos promoType="nuevos" props={props}/>

                <Row className="pt-3 pb-3">
                    <h2 style={{ fontSize: 12 }}>
                        Aprovecha las promociones que tenemos en nuestra Agencia Nissan
                    </h2>
                </Row>
            </Container>
        </Layout>
    )

}