import React, { useState, useRef, useCallback } from "react";
import { Tabs, Tab } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";

import WhatsApp from "../../../images/icons/whatsapp-alt-0.png";
import Llamada from "../../../images/icons/phone-alt-0.png";
import Mail from "../../../images/icons/mail-alt-0.png";

export default function FormularioVentaSalesforce({ children, pagina, btnTitulo, props, infoCar, infoCotiza, pagoMensual, sucursalId, paramsURL }) {
    console.log("InfoCar Formulario: ", infoCar);
    const [validationToken, setValidationToken] = useState("");
    const [selected, setSelected] = useState("WhatsApp");
    const [whatsapp, setWhatsApp] = useState("");
    const recaptchaRef = useRef();

    let propsSearc = props.location.search;
    const paramsSearch = new URLSearchParams(propsSearc);

    let source = paramsSearch.get("utm_source");
    let medium = paramsSearch.get("utm_medium");
    let campaing = paramsSearch.get("utm_campaign");
    let cnname = paramsSearch.get("cnname");
    let content = paramsSearch.get("utm_content");
    let term = paramsSearch.get("utm_term");


    function handleKeyPress(event) {
        setWhatsApp(event.target.value);
    }

    const handleVerify = useCallback(
        (token) => {
            setValidationToken(token);
        },
        [setValidationToken]
    );

    function verifyToken(event) {
        if (validationToken === "" || validationToken === null) {
            console.log("ESTA VACIO EL CAPTCH");
            alert("Error en el captcha");
            return;
        }
        event.target.submit();
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        verifyToken(event);
    };

    return (
        <>
            {/* <form action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" method="POST"> */}
            <form onSubmit={handleSubmit} action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&orgId=00DHp000005bwkK" method="POST" >

                <input type="hidden"
                    id="g-recaptcha-response"
                    name="g-recaptcha-response"
                    value={validationToken}></input>

                <div className="form-group" >

                    <input hidden name="oid" value="00DHp000005bwkK"/>
                    <input hidden name="retURL" value="https://nissanrivero.com/gracias-por-contactarnos/nissan"/>

                    <input hidden id="00NHp0000194HVl" maxlength="255" name="00NHp0000194HVl" size="20" type="text" value={`${infoCar.fullauto}`} />

                    <input hidden id="00NVn000002CXS5" maxlength="255" name="00NVn000002CXS5" size="20" type="text" value={content ? content : ""}/>

                    <input hidden id="00NVn000002CXVJ" maxlength="255" name="00NVn000002CXVJ" size="20" type="text" value={medium ? medium : ""}/>

                    <input hidden id="00NVn000002CXYX" maxlength="255" name="00NVn000002CXYX" size="20" type="text" value={source ? source : ""}/>

                    <input hidden id="00NVn000002CXbl" maxlength="255" name="00NVn000002CXbl" size="20" type="text" value={term ? term : ""}/>
     
                    <input hidden id="Campaign_ID" name="Campaign_ID" value={campaing ? campaing : "701Vn00000HA7g0IAD"} /> {/** cid */}

                    <input hidden id="GRI_Codigo_Campana__c" name="GRI_Codigo_Campana__c" value={campaing ? campaing : "701Vn00000HA7g0IAD"} />
   
                    <input hidden id="00NHp0000194HWd" maxlength="255" name="00NHp0000194HWd" size="20" type="text" value={cnname ? cnname : ""}/>
                    
                    {/*Plan Plazo:<input  id="00NHp0000194HW4" name="00NHp0000194HW4" size="20" type="text" />

                    Plan Enganche:<input  id="00NHp0000194HVz" name="00NHp0000194HVz" size="20" type="text" />

                    Plan Mensualidad:<input  id="00NHp0000194HUx" name="00NHp0000194HUx" size="20" type="text" />

                    Plan Anualidad:<input  id="00NHp0000194HVu" name="00NHp0000194HVu" size="20" type="text" />

                    Comentarios:<textarea  id="00NHp0000194HUr" name="00NHp0000194HUr" type="text" wrap="soft"></textarea>*/}

                    <input hidden id="00N2S000007ThUK" name="00N2S000007ThUK" value={`https://www.nissanrivero.com/${pagina}`} />

                    <input hidden name="retURL" value={`https://nissanrivero.com/gracias-por-contactarnos/` + pagina } />

                    <input hidden id="lead_source" name="lead_source" value="Internet" /> {/** Origen del prospecto */}


                    <input hidden id="url" name="url" value={pagina} />{/** pagina Origen */}
                    
                </div>

                {children}

                {
                    sucursalId ? "" : (
                        <div className="form-group pb-2">
                            <label className="pb-2" htmlFor="name">¿Agencia más cercana?</label>
                            <select
                                id="00NHp0000194HUD"
                                className="form-control"
                                name="00NHp0000194HUD"
                                title="Agencia"
                            >
                                    <option value="1043194">Contry</option>
                                    <option value="279130042">Valle Oriente</option>
                                    <option value="599457775">Valle</option>
                                    <option value="1582829098">Allende</option>
                            </select>
                        </div>
                    )
                }

                <div className="row">
                    <div className="col">
                        <div className="form-group pb-2">
                        <label className="pb-2" for="first_name">Nombre</label><input className="form-control pt-2" placeholder="Nombre" id="first_name" maxlength="40" name="first_name" size="20" type="text" required />
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group pb-2">
                            <label className="pb-2" for="last_name">Apellidos</label><input className="form-control pt-2" placeholder="Apellidos" id="last_name" maxlength="80" name="last_name" size="20" type="text" required />
                        </div>
                    </div>
                </div>
 
                <div className="row">
                    <div className="col">
                        <div className="form-group pb-2">
                            <label className="pb-2" for="email">Email</label><input className="form-control pt-2" placeholder="Correo" id="email" maxlength="80" name="email" size="20" type="text" required />
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group pb-2">
                            <label className="pb-2" for="mobile">Celular</label><input className="form-control pt-2" placeholder="Celular" id="mobile" maxlength="10" name="mobile" size="20" type="text" required />
                        </div>
                    </div>
                </div>
                <input hidden id="00NHp0000194HUw" name="00NHp0000194HUw"  value={source ? source : "Internet"}/>
                <div className="form-group pb-2" style={{ color: 'white' }}>
                    <p className="">
                        * Nunca compartiremos tus datos con nadie más.
                    </p>
                </div>
                <div className="form-group mb-3">
                    <center>
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey="6Le57eEaAAAAAFaBiKOIhLvbDBAXFqoJacXuJ4P3"
                            onChange={handleVerify}
                        />
                    </center>
                </div>
                <div className="center text-center mb-2">
                    <input type="submit"
                        id="btn-contactarserv"
                        className="boton boton-primario"
                        style={{ width: '80%' }}
                        value={btnTitulo}
                        onChange={verifyToken}
                    />
                </div>

                <br />

            </form>
        </>
    );
}