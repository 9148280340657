import React from "react";
import { Modal, Row, Col } from "react-bootstrap";
import GatsbyImage from "gatsby-image";
import FormularioSalesforce from "../widgets/formularios/FormularioSalesforce";

export default function ModalPromociones({show, onHide, selectPromo, promoType, props}){
     
    return(
        <Modal show={show} onHide={onHide} backdrop="static" keyboard={false} animation={false} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                Promociones de {promoType}
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col lg={6} md={12} style={{fontSize: 14}}>
                        <GatsbyImage
                            fluid={{
                                aspectRatio: 1 / 1,
                                src: `${selectPromo?.image}`,
                                srcSet: `${selectPromo?.image}, ${selectPromo?.image}`,
                                sizes: "quality: 80",
                            }}
                            className="w-100 rounded"
                            imgStyle={{objectFit: 'unset'}}
                        />
                        {selectPromo?.description}
                    </Col>
                    <Col lg={6} md={12}>
                        <h6 className="p-4 bg-light">
                            Formulario de Contacto {promoType}
                        </h6>

                        <FormularioSalesforce props={props} pagina={`promociones/`+promoType} btnTitulo="Solicitar promoción">
                            <input type="hidden" id="00Nf400000UBhZt" name="00Nf400000UBhZt" value="Nuevos" />
                            <input type="hidden" id="00Nf400000UBhZ5" name="00Nf400000UBhZ5" value={selectPromo ? selectPromo.description : ""} />
                        </FormularioSalesforce>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )

}